<template>
	<div class="chat-window" :class="{ open: state.open }">
		<header class="chat-window__header" @click="state.open = !state.open">
			Chats
			<img class="chat-window__chevron" src="/images/chat-icon-tgl.svg" alt="close">
		</header>
		<chat v-if="!isJobOwner" :job="job" :user="user" :active="true" :is-job-owner="false" @toggle-window="state.open = !state.open"></chat>

		<div v-else class="chats-screen" :class="{ empty: job.chats.length === 0 }">
			<div v-if="job.chats.length === 0">Aún no has recibido ningún mensaje...</div>
			<div v-else class="chat-list">
				<div v-for="chat in job.chats" :key="chat.id" class="chat-list__entry" @click="state.active = chat.id">
					<div class="chat-preview">
						<div class="user-logo">
							<img :src="chat.interested_user.logo_url" alt="user_logo" width="30">
						</div>
						<div class="chat-info">
                            <div v-if="chat.user_id === user.id && chat.job_id === null" class="chat-info__title" v-text="chat.interested_user.company"></div>
                            <!-- <div v-else-if="chat.user_id != user.id &&chat.job_id === null" class="chat-info__title" v-text="chat.user_id + ' (otro perfil)'"></div> -->
							<div v-else class="chat-info__title" v-text="chat.interested_user.company"></div>
							<div class="chat-info__time" v-text="getNotificationTime(chat.lastMessage.created_at)"></div>
							<div class="chat-info__message" v-text="chat.lastMessage.message"></div>
							<div v-show="chat.unread_messages_owner > 0" class="chat-info__notifications" v-text="chat.unread_messages_owner"></div>
						</div>
					</div>

					<chat :active="state.active === chat.id" :job="job" :chat="chat" :user="user" :is-job-owner="true" @hide="state.active = 0;"></chat>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, reactive } from '@vue/reactivity';
import { getNotificationTime } from '../functions.js';
import Chat from './Chat.vue';

export default {
	components: { Chat },
	props: {
		user: Object,
		job: Object,
        destination_user: Object,
	},
	setup(props){
		const urlParams = new URLSearchParams(window.location.search);

		const state = reactive({
			open: urlParams.has('chat'),
			active: parseInt(urlParams.get('chat')) ?? 0
		});

		const isJobOwner = computed(() => {
			return props.job.user_id == props.user.id;
		});

		return {
			state,
			isJobOwner,
			getNotificationTime
		}
	}
}
</script>
