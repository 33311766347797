function nl2br (str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

function getNotificationTime(timestamp){
    let sentAt = new Date(timestamp);

    if (new Date().toDateString() == sentAt.toDateString()){
        return `${("0" + sentAt.getHours()).slice(-2)}:${("0" + sentAt.getMinutes()).slice(-2)}`;
    }

    return `${sentAt.getDate()}/${sentAt.getMonth()+1}/${sentAt.getFullYear().toString().slice(2)}`;
}

export {
    nl2br,
    getNotificationTime
}

