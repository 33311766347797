<template>
    <div style="margin-top: 2rem">

        <h3 class="profile__tit profile__tit--accordion" @click="toggleReviews">OPINIONES ({{state.reviews.length}})</h3>


        <div v-show="state.showReviews">
            <div class="profile__accordion">
                <h3 v-if="!state.reviews.length">Esta empresa no ha recibido ninguna opinión</h3>
                <!-- <button v-if="state.canSubmit && !state.addReview" @click="addReview()" :class="{ 'js-open-login': !reviewerId }">Añadir opinión</button> -->
                <div v-show="state.addReview">
                    <div class="form__field">
                        <div class="form__field">
                            <label for="review_text">Escribe tu opinión...</label>
                            <textarea id="review_text" rows="4" style="width: 100%" v-model="state.review.text"></textarea>
                        </div>
                        <label for="review_rating">Puntuación</label>
                        <select id="review_rating" v-model="state.review.rating">
                            <option v-for="n in 5" :key="n" :value="n">{{n}}</option>
                        </select>
                    </div>

                    <button type="button" ref="submitBtn" @click="submitReview()">Enviar</button>
                    <p class="event-notice event-notice--error" style="margin-top: 0.5rem" v-show="state.error.length">{{ state.error }}</p>
                </div>

                <div v-if="state.reviews.length">
                    <!-- <b class='profile__opinions-title'>{{state.reviews.length}} <span v-if="state.reviews.length == 1">opinión</span><span v-else>opiniones</span>:</b> -->
                    <div class="opinion" v-for="review in state.reviews" :key="review.id">
                        <h3 class="business__name"><a :href='`/perfil/${review.reviewer?.slug}`'>{{ review.reviewer?.company }}</a></h3>
                        <div class="business__rating rating">
                            <span class="rating__stars">
                                <img :src="icon" alt="Star" class='rating__star' v-for="n in review.rating" :key="n">
                            </span>
                        </div>
                        <div class="opinion__date">{{ review.updated_at }}</div>
                        <div class="opinion__text">{{ review.review }}</div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>



    </div>

</template>

<script>
import { reactive, ref } from '@vue/reactivity'
export default {
    props:{
        canSubmit: Boolean,
        reviews: String,
        userId: Number,
        reviewerId: Number,
        icon: String,
    },
    setup(props){
        const state = reactive({
            reviews: JSON.parse(props.reviews),
            canSubmit: props.canSubmit,
            addReview: false,
            error: "",
            showReviews: false,
            review: {
                rating: 0,
                text: ""
            },
        });

        const submitBtn = ref(null);

        function addReview(){
            if(props.reviewerId){
                state.addReview = true;
            }
        }

        function toggleReviews() {
            state.showReviews = !state.showReviews; // Cambiar el valor de la variable al hacer clic en el título
        }

        function submitReview(){
            state.error = "";
            submitBtn.value.disabled = true;
            state.error = "";
            axios.post('/reviews/submit', {
				userId: props.userId,
				reviewerId: props.reviewerId,
                rating: state.review.rating,
                text: state.review.text
			})
			.then(response => {
                if(response.data.status == 'ok'){
                    state.canSubmit = state.addReview = false;
                    getReviews();
                }else{
                    state.error = response.data.message;
                }
			})
			.catch(error => {
				state.error = error.response.data.message;
			})
			.then(() => {
                submitBtn.value.disabled = false;
			});
        }

        function getReviews(){
            axios.post('/reviews/get', {
				userId: props.userId,
			})
			.then(response => {
                state.reviews = response.data.reviews;
                let rating = document.getElementById('rating');
                let ratingTemplate = "";
                for (let i = 0; i < response.data.reviews_avg; i++) {
                    ratingTemplate += `<img src="${props.icon}" alt="Star" class='rating__star'/>`;
                }
                ratingTemplate += ` (${response.data.reviews_count})`;
                rating.innerHTML = ratingTemplate;
			})
			.catch(error => {
				state.error = error.response.data.message;
			})
        }

        return {
            state,
            addReview,
            submitBtn,
            toggleReviews,
            submitReview
        }
    },
}
</script>
