<template>
    <h3>Servicios que ofrece la empresa</h3>
    <input type="text" v-on:keyup.enter="storeService" v-model="state.addInput"><button @click="storeService" type="button">Añadir</button>

    <ul class="services-list" ref="list">
        <li class="services-list__item" v-for="service in sortedServices" :key="service.id" :data-id="service.id">
            <div class="handle"></div>
            <input type="text" v-model="service.name" @blur="updateServices" v-on:keyup.enter="$event.target.blur()">
            <div class="delete" @click="deleteService(service)"></div>
        </li>
    </ul>
</template>

<script>
import { computed, reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import Sortable from 'sortablejs';

export default {
    props: {
        userId: Number,
        services: String,
        urls: String,
    },
    setup(props) {
        const list = ref(null);

        const config = {
            urls: JSON.parse(props.urls),
        }

        const state = reactive({
            services: JSON.parse(props.services),
            addInput: "",
        });

        const sortedServices = computed(() => {
            return state.services.sort((a, b) => (a.order > b.order) ? 1 : -1);
        });

        onMounted(() => {
            var sortable = Sortable.create(list.value, {
                handle: '.handle',
                animation: 150,
                ghostClass: 'ghost',
                onEnd: (e) => reorderModels(e),
            });
        });

        function reorderModels(e){
            if(e.oldIndex != e.newIndex){
                let listElements = [...list.value.children];
                listElements.forEach((listElem, index) => {
                    let match = state.services.findIndex(service => service.id == listElem.dataset.id);
                    if(match >= 0){
                        state.services[match].order = index;
                    }
                });
                updateServices();
            }
        };

        async function storeService(){
            if(state.addInput != ""){
                try {
                    const response = await axios.post(config.urls.store, {
                      service: state.addInput,
                    });
                    state.addInput = "";
                    state.services.push(response.data.service);
                    updateSidebar();
              } catch (error) {
                //   console.error(error);
              }
            }
        }

        async function updateServices(){
            try {
                const response = await axios.put(config.urls.update, {
                    services: state.services,
                });
                updateSidebar();                
            } catch (error) {
                // console.error(error);
            }
        }

        async function deleteService(service){
            try {
                const response = await axios.delete(config.urls.delete.replace("replace", service.id), {
                    services: state.services,
                });
                state.services.splice(state.services.indexOf(service),1);
                updateSidebar();
            } catch (error) {
                // console.error(error);
            }
        }

        function updateSidebar(){
            let sidebarServices = document.getElementById("business_services");
            let sidebarServicesList = sidebarServices.querySelector("ul");
            sidebarServicesList.innerHTML = "";
            sortedServices.value.forEach(service => {
                let li = document.createElement("li");
                li.textContent = service.name;
                sidebarServicesList.appendChild(li);
            });
        }

        return {
            state,
            list,
            config,
            storeService,
            sortedServices,
            deleteService,
            updateServices
        };
    },
}
</script>