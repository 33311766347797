<template>
    <form ref="ntfForm">
        <div class="notification-filters">
            <div class="notification-filters__left">
            </div>
            <div class="notification-filters__right">
                <div class="text-btn" ref="selectAll" @click="updateFilter('all')">Todos</div>

                <div class="ic ic--tag" @click="updateFilter('tagged')" :class="{ 'on': state.filters.tagged }">
                    <input type="checkbox" name="ntf_filters[]" value="tagged" v-model="state.filters.tagged"  />
                </div>
                <div class="ic ic--deal" @click="updateFilter('deal')" :class="{ 'on': state.filters.deal }">
                    <input type="checkbox" name="ntf_filters[]" value="deal" v-model="state.filters.deal" />
                </div>
                <div class="ic ic--send" @click="updateFilter('message')" :class="{ 'on': state.filters.message }">
                    <input type="checkbox" name="ntf_filters[]" value="message" v-model="state.filters.message" />
                </div>
                <div class="ic ic--chat" @click="updateFilter('chat')" :class="{ 'on': state.filters.chat }">
                    <input type="checkbox" name="ntf_filters[]" value="chat" v-model="state.filters.chat" />
                </div>
            </div>
        </div>
    </form>

    <div class="notification-list">
        <div v-if="state.filters.chat && !state.filters.tagged && state.notifications.filter(n => n.class === 'App\\Models\\Chat').length === 0">
            No hay notificaciones de chat disponibles.
        </div>
        <div v-if="state.filters.tagged && !state.filters.chat && state.notifications.filter(n => n.model.type == 'App\\Notifications\\TaggedNotification').length === 0">
            No hay notificaciones disponibles.
        </div>
        <div v-if="state.filters.deal && !state.filters.chat && state.notifications.filter(n => n.model.type == 'App\\Notifications\\OfferFactNotification').length === 0">
            No hay notificaciones disponibles.
        </div>
        <div v-if="state.filters.deal && !state.filters.chat && state.notifications.filter(n => n.model.type == 'App\\Notifications\\OfferAcceptedNotification').length === 0">
            No hay notificaciones disponibles.
        </div>
        <div v-if="state.filters.deal && !state.filters.chat && state.notifications.filter(n => n.model.type == 'App\\Notifications\\OfferReceivedNotification').length === 0">
            No hay notificaciones disponibles.
        </div>
        <div v-if="state.filters.deal && !state.filters.chat && state.notifications.filter(n => n.model.type == 'App\\Notifications\\OfferDeliveredNotification').length === 0">
            No hay notificaciones disponibles.
        </div>
        <div v-if="state.filters.deal && !state.filters.chat && state.notifications.filter(n => n.model.type == 'App\\Notifications\\PaymentNotification').length === 0">
            No hay notificaciones disponibles.
        </div>
        <div v-if="state.filters.deal && !state.filters.chat && state.notifications.filter(n => n.model.type == 'App\\Notifications\\NonPaymentNotification').length === 0">
            No hay notificaciones disponibles.
        </div>
        <div v-if="state.filters.message && !state.filters.deal && state.notifications.filter(n => n.model.type == 'App\\Notifications\\RecomendationNotification').length === 0">
            No hay notificaciones disponibles.
        </div>


        <!-- <a href="#" class="notification" v-if="state.notifications.length > 0" v-for="notification in state.notifications" > -->
            <template v-for="notification in state.notifications">
                <a :href="'notifications/read/' + notification.model.id + '/?redirect=1'" class="notification"  v-if="state.filters.deal && notification.model.type == 'App\\Notifications\\OfferFactNotification'" :class="deal">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.updated_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name"><strong>{{ notification.jobname }}</strong></div>
                        <div class="notification__name">{{ notification.message }}</div>
                    </div>
                </a>
                <a :href="'notifications/read/' + notification.model.id + '/?redirect=1'" class="notification"  v-else-if="state.filters.deal && notification.model.type == 'App\\Notifications\\OfferAcceptedNotification'" :class="deal">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.updated_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name"><strong>{{ notification.jobname }}</strong></div>
                        <div class="notification__name">{{ notification.message }}</div>
                    </div>
                </a>
                <a :href="'#'" class="notification"  v-else-if="state.filters.deal && notification.model.type == 'App\\Notifications\\DeleteOfferNotification'" :class="deal">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.updated_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name"><strong>{{ notification.jobname }}</strong></div>
                        <div class="notification__name">{{ notification.message }}</div>
                    </div>
                </a>
                <a :href="'notifications/read/' + notification.model.id + '/?redirect=1'" class="notification"  v-else-if="state.filters.deal && notification.model.type == 'App\\Notifications\\OfferReceivedNotification'" :class="deal">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.updated_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name"><strong>{{ notification.jobname }}</strong></div>
                        <div class="notification__name">{{ notification.message }}</div>
                    </div>
                </a>
                <a :href="'notifications/read/' + notification.model.id + '/?redirect=1'" class="notification"  v-else-if="state.filters.deal && notification.model.type == 'App\\Notifications\\OfferDeliveredNotification'" :class="deal">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.updated_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name"><strong>{{ notification.jobname }}</strong></div>
                        <div class="notification__name">{{ notification.message }}</div>
                    </div>
                </a>
                <a :href="'notifications/read/' + notification.model.id + '/?redirect=1'" class="notification"  v-else-if="state.filters.tagged && notification.model.type == 'App\\Notifications\\TaggedNotification'" :class="tagged">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.updated_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name">{{ notification.message }}</div>
                    </div>
                </a>
                <a :href="'notifications/read/' + notification.model.id + '/?redirect=1'" class="notification"  v-else-if="state.filters.message && notification.model.type == 'App\\Notifications\\RecomendationNotification'" :class="message">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.updated_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name">{{ notification.message }}</div>
                    </div>
                </a>
                <a :href="'notifications/read/' + notification.model.id + '/?redirect=1'" class="notification"  v-else-if="state.filters.deal && notification.model.type == 'App\\Notifications\\PaymentNotification'" :class="deal">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.created_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name">{{ notification.message }}</div>
                    </div>
                </a>
                <a :href="'notifications/read/' + notification.model.id + '/?redirect=1'" class="notification"  v-else-if="state.filters.deal && notification.model.type == 'App\\Notifications\\NonPaymentNotification'" :class="deal">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.created_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name">{{ notification.message }}</div>
                    </div>
                </a>
                <a href="#" class="notification"  v-else-if="state.filters.chat && notification.class == 'App\\Models\\Chat'" :class="chat">
                    <div class="notification__right">
                        <div class="notification__time">{{ getNotificationTime(notification.model.updated_at) }}</div>
                        <div class="notification__data">
                        </div>
                        <h2 class="notification__title">{{ notification.title }}</h2>
                        <div class="notification__name">{{ notification.model.lastMessage.message }}</div>
                    </div>
                </a>
            </template>

        <div class="spinner" v-show="state.loading"></div>
    </div>

</template>

<script>
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import { getNotificationTime } from '../functions.js';
export default {
    props:{
        fetchUrl: String,
    },
    setup(props) {
        const state = reactive({
            loading: true,
            notifications: [],
            filters: []
        });

        const config = {
            nextPageUrl: props.fetchUrl,
        }

        const selectAll = ref(null);
        const ntfForm = ref(null);

        watch(() => [state.filter], fetchResults);

        onMounted(() => {
            fetchResults();
            initializeFilters();
        });

        async function fetchResults() {
            state.loading = true;
            let formData = new FormData(ntfForm.value);
            await axios
                .get(props.fetchUrl, {
                    params: {
                        data: [...formData],
                    },
                })
                .then((response) => {
                    state.notifications = response.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    state.loading = false;
                });
        }

        function initializeFilters(){
            var categoryFilters = [...selectAll.value.parentElement.querySelectorAll('.ic')];
            var checkedCount = computeCheckCount(categoryFilters);
            selectAll.value.click();
        }

        function updateFilter(value) {
            switch (value) {
                case "all":
                    state.filters = { tagged: true, deal: true, message: true, chat: true };
                break;
                case "tagged":
                    state.filters = { tagged: true, deal: false, message: false, chat: false };
                break;
                case "deal":
                    state.filters = { tagged: false, deal: true, message: false, chat: false };
                break;
                case "message":
                    state.filters = { tagged: false, deal: false, message: true, chat: false };
                break;
                case "chat":
                    state.filters = { tagged: false, deal: false, message: false, chat: true };
                break;
            }
        }

        function computeCheckCount(categoryFilters) {
            return categoryFilters.filter((f) => f.classList.contains("on")).length;
        }

        return {
            state,
            config,
            selectAll,
            ntfForm,
            fetchResults,
            getNotificationTime,
            updateFilter
        };
    },
};
</script>
