<template>
  <div v-click-outside="clickOutside">
    <input 
      :id="id"
      type="search"
      class="input"
      autocomplete="off"
      autofill="off"
      @click="showList"
      v-on:keyup="getModels()"
      v-model="state.search"/>

    <div v-show="state.search != ''" class="search-list" :id="listId">
      <div v-if="state.loading" class="search-list__feedback">
        <div class="spinner"></div>
        <small style="margin-top: 1.5rem">Obteniendo resultados...</small>
      </div>

      <div v-else>
        <div v-if="state.results.length > 0 && state.search != ''">
          <div 
            v-for="model in state.results"
            :key="model.id"
            @click="selectModel(model)"
            class="search-list__item"
            :class="{ active: state.model.id == model.id }">
            {{ model.name }}
          </div>
        </div>

        <div v-else-if="state.error != ''" class="search-list__feedback">
          <div class="search-list__feedback--error"></div>
          <small style="margin-top: 1.5rem">{{ state.error }}</small>
        </div>
      </div>
    </div>
  </div>

  <input type="text" :name="name" :value="state.model?.id" required style="position:absolute;bottom:0;z-index: -1;" />
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-core";
import { SEARCH_TIMEOUT } from "../app";

var searchTimeout;

export default {
  props: {
    id: String,
    name: String,
    requestUrl: String,
    model: String,
  },

  setup(props) {
    const state = reactive({
      model: {},
      results: [],
      error: "",
      search: "",
      loading: false,
    });

    onMounted(() => {
      if(props.model){
        state.model = JSON.parse(props.model);
        state.results.push(JSON.parse(props.model));
        state.search = state.model.name;
      } 
    });

    const listId = computed(() => {
      return props.id + "-list";
    });

    watch(
      () => state.results,
      (results, prevResults) => {
        switch (results.length) {
          case 0:
            state.error = "Sin resultados";
            break;

          case 1:
            state.model = results[0];
            state.search = state.model.name;
            break;
        }
      }
    );

    function showList() {
      if(state.results.length > 0 || state.loading){
        document.getElementById(listId.value).style.display = "block";
      }
    }

    function clickOutside(e) {
      document.getElementById(listId.value).style.display = "none";
    }

    function getModels() {
      state.model = {};
      if (state.search != "") {
        state.loading = true;
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
          showList();
          axios
            .get(props.requestUrl, {
              params: {
                name: state.search,
              },
            })
            .then((response) => {
              state.results = response.data;
              state.loading = false;
            })
            .catch((error) => {
              state.loading = false;
              state.error = error.message;
            });
        }, SEARCH_TIMEOUT);
      } else {
        state.results = [];
      }
    }

    function selectModel(model) {
      state.model = model;
      state.search = model.name;
      document.getElementById(listId.value).style.display = "none";
    }

    return {
      state,
      getModels,
      selectModel,
      clickOutside,
      showList,
      listId,
    };
  },
};
</script>
