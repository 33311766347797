<template>
	<div class="message" :class="messageClass">
		<div>{{ message.message }}</div>
		<div v-if="message.files?.length > 0">
			<details>
				<summary>Ver archivos</summary>
				<ul class="message-filelist">
					<li v-for="file in message.files">
						<a :href="file.url" v-text="file.filename" target="_blank" :download="file.filename"></a>
					</li>
				</ul>
			</details>
		</div>
		<small class="time">{{ time }}</small>
	</div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
export default {
	props: {
		user: Object,
		message: Object,
	},
	setup(props) {
		const messageClass = computed(() => {
			return reactive({
				'message--right': props.message.user_id === props.user.id,
				'message--left': props.message.user_id !== props.user.id,
				'loading': props.message.loading,
			});
		});

		const time = computed(() => {
			let date = new Date(props.message.created_at);
			let hours = ("0" + date.getHours()).slice(-2);
			let mins = ("0" + date.getMinutes()).slice(-2);
			return `${hours}:${mins}`;
		});

		return {
			messageClass,
			time,
		};
	},
};
</script>