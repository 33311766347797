<template>
  <div class="content">
    <div class="container container--box">
      <div class="columns is-mobile is-multiline">
        <div class="column is-12-mobile is-8-tablet">
          <h1 class="product__name">Empresas Colaboradoras</h1>
        </div>
        <div class="column is-12-mobile is-4-tablet product-header__right">
          <button type="button" class="button" data-fancybox data-src="#add-collaboration">Nueva Lista</button>
        </div>
      </div>

			<hr>

			<button v-if="state.collaborations.length" type="button" class="button" data-fancybox data-src="#add-collaborator" style="margin-top: 1rem">Añade una empresa</button>

      <!-- Listas -->
			<div class="collab-list">
				<collaboration v-for="(collaboration,index) in state.collaborations"
				:key="index"
				:order="index"
				:icons="icons"
				:collaboration="collaboration"
                :collaborations="state.collaborations"
				:openAccordion="true"
				@deleteCollaboration="deleteCollaboration"
				></collaboration>
	  	</div>
		</div>

		<!-- Modal nueva lista -->
		<div style="display: none; width: 780px" id="add-collaboration" ref="storeFancybox">
			<h2 class="title-2">Nombra la nueva lista...</h2>
			<div style="margin-bottom: 1rem;">
				<input type="text" ref="addCollaboration" class="input" v-on:keyup.enter="storeCollaboration">
			</div>
			<button type="button" class="button" ref="addCollaborationBtn" @click="storeCollaboration">Añadir</button>
		</div>

		<!-- Modal añadir empresa a lista -->
		<div style="display: none; width: 780px" id="add-collaborator" ref="searchFancybox">
			<h2 v-if="!isPopup" class="title-2">Busca la empresa por nombre o por CIF</h2>
			<div class="search-collaborators" style="margin-bottom: 1rem;">
				<input v-if="!isPopup" class="input search-collaborators__input" type="search" ref="searchCollaboratorsInput" v-model="state.searchCollaborator" v-on:keyup="searchCollaborators">
				<div v-if="!state.noResults">
					<div class="search-collaborators__results" ref="collaboratorsResults">
						<div class="search-item" v-for="(collaborator,index) in state.searchResults" :key="index" @click="selectCollaborator(collaborator, $event)">
							{{collaborator.company}}
						</div>
					</div>
					<div v-if="state.selectedResult?.id" style="margin-top: 1rem">
						<h4>Selecciona las listas en las que quieres añadir <span v-if="state.selectedResult?.id">a {{ state.selectedResult.company }}</span></h4>
						<div v-if="state.collaborations.length > 0" v-for="(collaboration, index) in state.collaborations" :key="index" style="margin-top: 0.3rem">
							<input type="checkbox" :id="'collaboration' + collaboration.id" @click="pushToCollaborations(collaboration.id)">
							<label :for="'collaboration' + collaboration.id">{{ collaboration.name }}</label>
                            <li style="margin-left: 15px;" v-for="collaborate in collaboration.collaborators">{{ collaborate.company }}</li>

						</div>
						<div v-else> Debes crear una lista antes de poder añadir empresas.</div>
						<div class="text-center" style="margin-top: 1rem">
							<button style="margin-top: 1rem" type="button" class="button" :disabled="state.selectedResult?.id && !state.attachToCollaborations.length" @click="attachCollaborator">Guardar selección</button>
						</div>
						<div v-if="state.error" @click="state.error=''" class="event-notice event-notice--error" style="margin-top: 1rem">{{ state.error }}</div>
					</div>
				</div>
				<div v-else>
					No se han encontrado resultados
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { nextTick } from "@vue/runtime-core";
import Collaboration from "../components/Collaboration.vue";
import { onMounted, watch } from '@vue/runtime-core';
import { SEARCH_TIMEOUT } from '../app';

export default {
  components: {
    Collaboration,
  },
  props: {
		userId: Number,
    icons: Object,
		searchUrl: String
  },
  computed: {
    currentPath() {
      return window.location.pathname;
    },
    isPopup() {
      return this.currentPath.includes("popup");
    }
  },
  setup(props) {
		/**
		 * DOM Elements
		 */
		const addCollaboration = ref(null);
		const addCollaborationBtn = ref(null);
		const collaboratorsResults = ref(null);
		const storeFancybox = ref(null);
		const searchCollaboratorsInput = ref(null);

		var searchTimeout;

		const searchParams = new URLSearchParams(new URL(document.URL).search);
		const searchId = searchParams.get('id');

		const state = reactive({
			collaborations: [],
			searchResults: [],
			searchCollaborator: null,
			selectedResult: {},
			noResults: false,
			attachToCollaborations: [],
			error: "",
		});

		onMounted(() => {
			getCollaborations();
			if (searchId) findCollaborator();
		});

		watch(() => state.searchCollaborator, (input, prevInput) => {
			if(state.searchCollaborator == ""){
				state.searchResults = [];
				state.selectedResult = {};
				state.attachToCollaborations = [];
				state.error = "";
			}
		})

		/**
		 * Funció que executa la petició per obtenir colaboradors (users) que el seu nom d'empresa coincideixi amb l'input introduït (state.searchCollaborator)
		 */
		function searchCollaborators(){
			if(state.searchCollaborator != ""){
				clearTimeout(searchTimeout);
				searchTimeout = setTimeout(() => {
					axios.get(props.searchUrl, {
						params: {
							input: state.searchCollaborator,
						}
					})
					.then((response) => {
						state.searchResults = response.data;
						state.noResults = !state.searchResults.length;
					}).catch((error)=>{
						console.log(error);
					});
				}, SEARCH_TIMEOUT);
			}
		}

		/**
		 * Funció que executa la petició per obtenir colaboradors (users) que el seu nom d'empresa coincideixi amb l'input introduït (state.searchCollaborator)
		 */
		function findCollaborator(){
			axios.get(props.searchUrl, {
				params: {
					user_id: searchId,
				}
			})
			.then(async (response) => {
				state.noResults = false;
				state.selectedResult = response.data;
				$.fancybox.open({
					src: '#add-collaborator',
					modal: false,
					opts : {
						afterShow : async(instance, current) => {
							await nextTick();
							searchCollaboratorsInput.value.blur();
						}
					}
				});
			}).catch((error)=>{
				alert('No se ha encontrado la empresa solicitada');
			});
		}

		/**
		 * @param collaborator Object
		 * @param event Click Event
		 * Funció per assignar la classe 'active' al resultat de la cerca que s'hagi fet click
		 */
		function selectCollaborator(collaborator, event){
			state.selectedResult = collaborator;
			var children = collaboratorsResults.value.children;

			if(event.target.classList.contains('active')){
				state.selectedResult = {};
				state.attachToCollaborations = [];
				state.error = "";
				event.target.classList.remove('active');
			}else{
				for (let i = 0; i < children.length; i++) {
					children[i].classList.remove('active');
				}
				event.target.classList.add('active');
			}
		}

		/**
		 * @param collaborationId Object
		 * Funció que guarda les colaboracions on més tard s'afegirà el colaborador seleccionat
		 */
		function pushToCollaborations(collaborationId){
			if(state.attachToCollaborations.includes(collaborationId)){
				let index = state.attachToCollaborations.indexOf(collaborationId);
				state.attachToCollaborations.splice(index, 1);
			}else{
				state.attachToCollaborations.push(collaborationId);
			}
		}

		/**
		 * Funció que executa la petició per emmagatzemar el colaborador seleccionat (state.selectedResult) a les colaboracions seleccionades (state.attachToCollaborations)
		 */
		function attachCollaborator(){
			if(state.selectedResult?.id && state.attachToCollaborations.length){
				axios.post('/colaboraciones/attach', {
					collaboratorId: state.selectedResult.id,
					collaborations: state.attachToCollaborations
				})
				.then(function (response) {
					getCollaborations();
					state.searchCollaborator = "";
					state.selectedResult = {};
					state.attachToCollaborations = [];
					state.error = "";
					$.fancybox.close();
				})
				.catch(function (error) {
					state.error = error.response.data.message;
				})
				.then(function () {
					addCollaborationBtn.value.disabled = false;
				});
			}
		}

		/**
		 * Funció que executa la petició per obtenir totes les colaboracions de l'usuari
		 */
		function getCollaborations(){
			axios.get('/colaboraciones/get')
			.then(function (response) {
				state.collaborations = response.data;

				//  setInterval(() => {
							//     // Initialize tooltipster menu tooltips
							//     $('.js-tooltip-white').tooltipster({
							//         trigger: 'click',
							//         contentAsHTML: true,
							//         interactive: true,
							//         animationDuration: 0,
							//         side: 'bottom',
							//         theme: 'tooltipster-white',
							//         functionBefore: function (instance, helper) {
							//         var btn = helper.origin
							//         btn.classList.add('on')
							//         },
							//         functionAfter: function (instance, helper) {
							//         var btn = helper.origin
							//         btn.classList.remove('on')
							//         }
							//     })
							// }, 3000);
			})
			.catch(function (error) {
				alert('Ha ocurrido un error');
			});
		}

		/**
		 * Funció que executa la petició per emmagatzemar una nova colaboració
		 */
		function storeCollaboration(){
			var name = addCollaboration.value.value;
			if(name!=""){
				addCollaborationBtn.value.disabled = true;
				axios.post('/colaboraciones/store', {
					name: name,
					collaborationId: -1
				})
				.then(function (response) {
					state.collaborations.push(response.data.collaboration);
					addCollaboration.value.value = "";
					$.fancybox.close();
				})
				.catch(function (error) {
					alert('Ha ocurrido un error');
				})
				.then(function () {
					addCollaborationBtn.value.disabled = false;
				});
			}
		}

		/**
		 * @param collaborationId Number
		 * Funció que executa la petició per eliminar una colaboració
		 */
		function deleteCollaboration(collaborationId){
			axios.delete('/colaboraciones/destroy/' + collaborationId)
			.then(function (response) {
				// handle success
				getCollaborations();
				state.collaborations = response.data;
			})
			.catch(function (error) {
				// handle error
				alert('Ha ocurrido un error');
			})
			.then(function () {
				// always executed
			});
		}

    return {
      state,
			addCollaboration,
			addCollaborationBtn,
			collaboratorsResults,
			storeFancybox,
			searchCollaboratorsInput,
			getCollaborations,
			searchCollaborators,
			selectCollaborator,
			pushToCollaborations,
			storeCollaboration,
			deleteCollaboration,
			attachCollaborator,
    };
  },
};
</script>
