require('./bootstrap');

window.Vue = require("vue").default;

const axios = require("axios");

export const SEARCH_TIMEOUT = 300;

import { createApp } from "vue";
import LocationPicker from "./components/LocationPicker.vue";
import JobsFeed from "./components/JobsFeed.vue";
import ProfileFeed from "./components/ProfileFeed.vue";
import OffersFeed from "./components/OffersFeed.vue";
import BusinessFeed from "./components/BusinessFeed.vue";
import UserCollaborations from "./components/UserCollaborations.vue";
import UserServices from "./components/UserServices.vue";
import Collaboration from "./components/Collaboration.vue";
import Searchbar from "./components/Searchbar.vue";
import Reviews from "./components/Reviews.vue";
import NotificationFeed from "./components/NotificationFeed.vue";
import JobActions from "./components/JobActions.vue";
import JobCard from "./components/JobCard.vue";
import OfferCard from "./components/OfferCard.vue";
import ChatWindow from "./components/ChatWindow.vue";
import Chat from "./components/Chat.vue";
import ChatMessage from "./components/ChatMessage.vue";

const app = createApp({
    components: {
        LocationPicker,
        JobsFeed,
        ProfileFeed,
        OffersFeed,
        BusinessFeed,
        UserCollaborations,
        UserServices,
        Collaboration,
        Searchbar,
        Reviews,
        NotificationFeed,
        JobActions,
        JobCard,
        OfferCard,
        ChatWindow,
        Chat,
        ChatMessage,
    },
});

//Click outside component event --> LocationPicker.vue
app.directive('click-outside', {
  beforeMount(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

app.mount("#app");

$(() => {

  // Fancybox default overrides

  $.fancybox.defaults.touch = false
  $.fancybox.defaults.btnTpl.smallBtn = '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
  '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59Z" fill="black"/></svg>' +
  "</button>";

  // Search bar toggle inputs
  $('.search-bar__input').on('focus', function () {
    $('.js-search').addClass('active')
  })
  $('.search-bar__input').on('blur', function () {
    $('.js-search').removeClass('active')
  })

  // Toggle filters
  $('.js-filters-tgl').on('click', function () {
    $('.filters__content').toggle()
  })

  // Open login
  $('.js-open-login').on('click', function () {
		$.fancybox.open({
			src: '.login-form',
			type: 'inline',
      touch : false
		})
  })

  // Read more button
  $('.js-read-more').on('click', function () {
    $(this).hide()
    $('.js-read-more-txt').slideDown()
  })

  // Fancybox iframe opts
  $('[data-type="iframe"]').fancybox({
    toolbar: false,
    iframe: {
      css: {
        width: '600px'
      }
    }
  });

  // Initialize tooltipster menu tooltips
  $('.js-tooltip-black').tooltipster({
    trigger: 'click',
    contentAsHTML: true,
    interactive: true,
    animationDuration: 0,
    side: 'bottom',
    debug: false,
    theme: 'tooltipster-black',
    functionBefore: function (instance, helper) {
      var btn = helper.origin
      btn.classList.add('on')
    },
    functionAfter: function (instance, helper) {
      var btn = helper.origin
      btn.classList.remove('on')
    }
  });

   // Initialize tooltipster ad tooltip
   $('.js-tooltip-white').tooltipster({
    trigger: 'click',
    contentAsHTML: true,
    interactive: true,
    animationDuration: 0,
    minWidth: 250,
    debug: false,
    side: 'bottom',
    theme: 'tooltipster-white',
    functionBefore: function (instance, helper) {
      var btn = helper.origin
      btn.classList.add('on')
    },
    functionAfter: function (instance, helper) {
      var btn = helper.origin
      btn.classList.remove('on')
    }
  });

  $('.js-tab-tgl').on('click', function () {
    var target = this.dataset.target;
    var group = this.dataset.group;

    $(`.js-tab-tgl[data-group=${group}]`).removeClass('active');
    $(this).addClass('active');

    $(`.js-tab[data-group=${group}]`).fadeOut(160);
    $(`.js-tab[data-tab=${target}]`).delay(160).fadeIn(160);
  })

})
