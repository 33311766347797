<template>
	<div ref="chatEl" class="chat" :class="{ active: active }">
		<div class="chat__header" @click="toggleWindow">
			<img v-if="isJobOwner" @click.stop="$emit('hide')" class="chat-navigate-back" src="/images/chat-icon-tgl.svg" alt="close">
			<div class="user-logo">
				<img :src="state.chatHeader.logo" alt="user_logo">
			</div>
			<b v-text="state.chatHeader.title"></b>

			<a class="show-profile" v-if="isJobOwner" :href="`/perfil/${chat.interested_user.slug}`" target="_blank">
				<button class="button" type="button">Ver perfil</button>
			</a>
			<img v-else class="chat-window__chevron" src="/images/chat-icon-tgl.svg" alt="close">
		</div>
		<div class="chat__body" ref="chatBody" :class="{loading: state.loading}">
			<div class="chat-messages">
				<div class="spinner" v-show="state.loading"></div>

				<chat-message
					v-for="message in state.messages"
					:key="message.id"
					:message="message"
					:user="user"
				>
				</chat-message>
			</div>
		</div>
		<div class="chat__footer">
			<div v-show="state.showAttachments" class="message-attachments">
				<input type="file" ref="fileInput" @change="addFiles" multiple>
				<small>Max. {{ MAX_FILE_SIZE }} MB</small>
			</div>

			<div @click="state.showAttachments = !state.showAttachments">
				<svg class="attachment-icon" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M2 12.5C2 9.46 4.46 7 7.5 7H18C20.21 7 22 8.79 22 11C22 13.21 20.21 15 18 15H9.5C8.12 15 7 13.88 7 12.5C7 11.12 8.12 10 9.5 10H17V12H9.41C8.86 12 8.86 13 9.41 13H18C19.1 13 20 12.1 20 11C20 9.9 19.1 9 18 9H7.5C5.57 9 4 10.57 4 12.5C4 14.43 5.57 16 7.5 16H17V18H7.5C4.46 18 2 15.54 2 12.5Z" fill="currentColor"/>
				</svg>
			</div>

			<input
				class="chat__input"
				@keyup.enter="addMessage"
				type="text"
				placeholder="Escribe tu mensaje..."
				ref="inputEl"
				v-model="state.input"
			/>
			<svg @click="addMessage" class="send-icon" width="27" height="27" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M6.00708 19.75L20.875 13.375L6.00708 7L6 11.9583L16.625 13.375L6 14.7917L6.00708 19.75Z" fill="currentColor"/>
			</svg>
		</div>
	</div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import { onMounted, onUpdated, watch } from "@vue/runtime-core";
import { getNotificationTime } from '../functions.js';
import ChatMessage from './ChatMessage.vue';

export default {
	components: { ChatMessage },
	emits: ["hide", "toggleWindow"],
	props: {
		user: Object,
		job: Object,
		chat: Object,
		isJobOwner: Boolean,
		active: Boolean
	},
	setup(props, { emit }) {
		const MAX_FILES_NUMBER = 4;
		const MAX_FILE_SIZE = 10;
		const inputEl = ref(null);
		const chatEl = ref(null);

		const state = reactive({
			input: '',
			messages: [],
			listening: false,
			chat: props.chat ?? props.job.chats[0],
			loading: false,
			showAttachments: false,
			chatHeader: {},
		});

		const chatBody = ref(null);
		const fileInput = ref(null);

		var chatInfoElems;

		watch(() => [props.active], () => {
			markRead();
		});

		onMounted(() => {
			if (props.isJobOwner) {
				state.chatHeader = {
					title: state.chat.interested_user.company,
					logo: state.chat.interested_user.logo_url
				};
			}
			else {
				state.chatHeader = {
					title: props.job.user.company,
					logo: props.job.user.logo_url
				};
			}

			if (state.chat) {
				fetchMessages(state.chat.id);
				openConnection();
			}

			chatInfoElems = {
				title: chatEl.value.previousSibling.querySelector('.chat-info__title'),
				time: chatEl.value.previousSibling.querySelector('.chat-info__time'),
				message: chatEl.value.previousSibling.querySelector('.chat-info__message'),
				notifications: chatEl.value.previousSibling.querySelector('.chat-info__notifications'),
			}
		});

		onUpdated(() => {
			chatBody.value.scrollTop = chatBody.value?.scrollHeight;
		});

		function openConnection(){
			window.Echo.private(`chats.${state.chat.id}`).listen("ChatMessageSent", (e) => {
				state.messages.push(e.message);
				state.chat.lastMessage = e.message;

				if (props.isJobOwner) {
					state.chat.unread_messages_owner++;
					updatePreviewLastMsg();
				} else {
					state.chat.unread_messages_interested_user++;
				}

				if (props.active) {
					markRead();
				}
			});

			state.listening = true;
		}

		function closeConnection() {
			window.Echo.leave(`chats.${state.chat.id}`);
		}

		function fetchMessages(chatId) {
			state.loading = true;

			// TODO: messages pagination
			axios.get("/chat/get-messages", {
				params: {
					chat_id: chatId,
				}
			}).then(response => {
				state.messages = response.data;
				if (props.isJobOwner) updatePreviewLastMsg();
				state.loading = false;
			}).catch(e => {
				alert('No se han podido recuperar los mensajes.');
			});
		}

		function addMessage() {
			if (state.input.trim() || fileInput.value.files.length > 0) {

				let messageObj = reactive({
					user_id: props.user.id,
					message: state.input,
					created_at: Date.now(),
					loading: true,
				});

				state.messages.push(messageObj);

				let formData = new FormData();
				formData.append('job_id', props.job.id);
                formData.append('url', window.location.pathname);
				formData.append('chat_id', state.chat?.id ?? '');
				formData.append('message', state.input);

				for (const file of fileInput.value.files) {
					formData.append('files[]', file);
				}
				state.showAttachments = false;

				axios
					.post("/chat/send-message", formData, {
						headers: {
      				'Content-Type': 'multipart/form-data',
    				}
					})
					.then(response => {
                        state.chat = response.data.data.chat;
						if (!state.listening) {
							openConnection();
						}
						// Remove last message to push new one (-> files)
						state.messages.pop();
						state.messages.push(state.chat.lastMessage);

						if (props.isJobOwner) updatePreviewLastMsg();

						// Clear file input files
						fileInput.value.value = null;
						// Hide loader
						messageObj.loading = false;
					})
					.catch(e => {
						alert('No se ha podido enviar el mensaje.');
					});

				state.input = '';
			}
		}

		function addFiles(e) {
			let files = [...fileInput.value.files];

			if (files.length > MAX_FILES_NUMBER) {
				fileInput.value.value = null;
				alert('Se permite un máximo de 4 archivos por mensaje');
			}

			if (files.some(file => (file.size / 1000000) > MAX_FILE_SIZE)) {
				fileInput.value.value = null;
				alert(`Un archivo adjunto no debe sobrepasar los ${MAX_FILE_SIZE}MB`);
			}
		}

		function markRead(){
			let hasUnreadMessages;
			if (props.isJobOwner) {
				hasUnreadMessages = state.chat.unread_messages_owner > 0;
			}else {
				hasUnreadMessages = state.chat.unread_messages_interested_user > 0;
			}

			if (props.active && hasUnreadMessages) {
				axios.post('/chat/mark-read', {
					chat_id: state.chat.id,
				})
				.then(function (response) {
					if (props.isJobOwner) {
						state.chat.unread_messages_owner = 0;
						chatInfoElems.notifications.style.display = 'none';
						chatInfoElems.notifications.innerHTML = '';
					} else {
						state.chat.unread_messages_interested_user = 0;
					}
				})
			}
		}

		function updatePreviewLastMsg(){
			// Update timestamp
			chatInfoElems.time.textContent = getNotificationTime(state.chat.lastMessage.created_at);

			// Update notifications
			if (state.chat.unread_messages_owner > 0) {
				chatInfoElems.notifications.style.display = 'grid';
				chatInfoElems.notifications.textContent = state.chat.unread_messages_owner;
			}

			// Update last message
			let fileCount = state.chat.lastMessage.files.length;
			if (fileCount > 0) {
				if (fileCount === 1) chatInfoElems.message.textContent = '📄 1 archivo adjunto';
				else chatInfoElems.message.textContent = `📄 ${fileCount} archivos adjuntos`;
			} else {
				chatInfoElems.message.textContent = state.chat.lastMessage.message;
			}
		}

		function toggleWindow() {
			if (!props.isJobOwner) {
				emit('toggleWindow');
			}
		}

		return {
			MAX_FILE_SIZE,
			state,
			chatEl,
			inputEl,
			addMessage,
			chatBody,
			fileInput,
			closeConnection,
			addFiles,
			toggleWindow
		};
	},
};
</script>
