<template>
  <div class="filters-box">
    <div class="filters-tabs">
        <a v-if="loggedId && certificate === 'Sí'" href="/" class="filters-tabs__link"><span>Anuncios</span></a>
        <a v-else class="filters-tabs__link"><span>Anuncios</span></a>
        <a v-if="loggedId && certificate === 'Sí'" @click="redirectToSearch" class="filters-tabs__link filters-tabs__link--select"><span>Empresa</span></a>
        <a v-else class="filters-tabs__link filters-tabs__link--select"><span>Empresa</span></a>
    </div>
    <div class="filters">
      <div class="filters__main">
        <label for="sorting" class="filters__sort-label">Ordenar por</label>
        <select name="" id="sorting" class="filters__select" v-model="state.filter">
          <option :value="`${config.filters.recent}|desc`" selected>Más recientes</option>
          <option :value="`${config.filters.delivery}|asc`">Plazo Ascendente</option>
          <option :value="`${config.filters.delivery}|desc`">Plazo Descendente</option>
          <option :value="`${config.filters.popular}|desc`">Más Ofertados</option>
        </select>
      </div>
      <div class="filters__right">
        <button class="button filters__tgl js-filters-tgl">Filtros</button>
        <div class="filters__content">
          <span class="filters__geoloc">
            <span class="filters__geoloc-label">Geolocalización</span>
            <button class="filters__btn" v-if="!state.location" @click="getLocation">Activar</button>
            <button class="filters__btn" v-else @click="state.location = null">Desactivar</button>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="columns is-multiline">
    <job-card v-for="job in state.jobs" :key="job.id" :job="job" :loggedId="loggedId" :certificate="certificate" :icons="icons"></job-card>
  </div>

  <div class="spinner" v-show="state.loading"></div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { onMounted, watch } from "vue";
import JobCard from './JobCard.vue';

export default {
  components: { JobCard },
  props: {
    fetchUrl: String,
    icons: Object,
    search: String,
    loggedId: String,
    certificate: String,
  },

  setup(props) {
    const config = {
      nextPageUrl: props.fetchUrl,
      filters: {
        recent: "recent",
        delivery: "delivery",
        offered: "offered"
      }
    };

    const state = reactive({
      jobs: [],
      loading: true,
      location: null,
      filter: config.filters.recent + '|desc',
    });

    watch(() => [state.filter, state.location], fetchResults);

    onMounted(() => {
        fetchResults();
        window.onscroll = function (e) {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !state.loading) {
              fetchMoreResults();
            }
        };
    });

    async function fetchResults(){
      state.loading = true;
      let filter = state.filter.split('|');

      await axios
        .get(props.fetchUrl, {
          params: {
            search: props.search,
            orderBy: filter[0],
            location: JSON.stringify(state.location),
            sort: filter[1]
          }
        })
        .then((response) => {
          config.nextPageUrl = response.data.next_page_url;
          state.jobs = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    }

    async function fetchMoreResults() {
      if (config.nextPageUrl != null) {
        state.loading = true;
        await axios
          .get(config.nextPageUrl)
          .then((response) => {
            config.nextPageUrl = response.data.next_page_url;
            state.jobs.push(...response.data.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
          state.loading = false;
        });
      } else {
        console.log("No more results");
      }
    }

    function redirectToSearch() {

        window.location.href = `/busqueda/${encodeURIComponent('empresa')}`;
    }

    function getLocation() {
      if (!navigator.geolocation) {
        alert("La funcionalidad de geolocalización no es compatible con este navegador.");
      } else {

        navigator.permissions.query({name: 'geolocation'})
        .then((permission) => {
          if(permission.state == "denied"){
            alert("Debes activar la geolocalización en el navegador para poder usar esta función");
          }else{
            navigator.geolocation.getCurrentPosition(success, error);
          }
        })
        .catch((error) => {
          alert("No se ha podido obtener el estado del permiso de geolocalización");
        })
      }

      function success(position) {
        state.location = {
          coords: {
          accuracy: position.coords.accuracy,
          altitude: position.coords.altitude,
          altitudeAccuracy: position.coords.altitudeAccuracy,
          heading: position.coords.heading,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          speed: position.coords.speed,
        },
        timestamp: position.timestamp,
        };
      }

      function error() {
        state.location = null;
      }
    }

    return {
      state,
      config,
      fetchResults,
      fetchMoreResults,
      getLocation,
      redirectToSearch,
    };
  },
};
</script>
