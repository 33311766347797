<template>
  <div class="profile-filters">
        <div class='profile-filters__filter'>
            <label for="filters_search">Buscar:</label>
            <input class='filters__input'
                   type="text"
                   id="filters_search"
                   autocomplete="off"
                   autofill="off"
                   v-on:keyup="searchChanged"
                   v-model="state.search"
            />
        </div>

        <div class='profile-filters__state'>
            <label for="filters_state" class="filters__sort-label">Estado: </label>
            <select id="filters_state" class='filters__select' v-model="state.state">
                <option :value="`${config.state.favorite}`" selected>Pendiente ofertar</option>
                <option :value="`${config.state.offered}`" >Ofertado</option>
                <option :value="`${config.state.active}`">Pte. Entrega</option>
                <option :value="`${config.state.delivered}`">Entregado</option>
                <!-- <option :value="`${config.state.deleted}|desc`">Eliminado</option> -->
            </select>
        </div>

        <div class='profile-filters__filter'>
            <label for="filters_sorting" class="filters__sort-label">Ordenar por</label>
            <select id="filters_sorting" class="filters__select" v-model="state.orderBy">
                <option :value="`${config.orderBy.recent}|desc`" selected>Más recientes</option>
                <option :value="`${config.orderBy.delivery}|asc`">Plazo Ascendente</option>
                <option :value="`${config.orderBy.delivery}|desc`">Plazo Descendente</option>
                <option :value="`${config.orderBy.popular}|desc`">Más Ofertados</option>
            </select>
        </div>


    </div>

  <div class="columns is-multiline" v-if="!state.loading">
    <job-card v-if="state.state == config.state.favorite" v-for="job in state.results" :key="job.id" :job="job" :loggedId="loggedId" :icons="icons"></job-card>
    <offer-card v-else v-for="offer in state.results" :key="offer.id" :offer="offer" :icons="icons"></offer-card>
  </div>

  <div class="spinner" v-show="state.loading"></div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { onMounted, watch } from "vue";
import { SEARCH_TIMEOUT } from "../app";
import JobCard from './JobCard.vue';
import OfferCard from './OfferCard.vue';

var searchTimeout;

export default {
	components: { JobCard, OfferCard },
  props: {
    fetchUrl: String,
    icons: Object,
    loggedId: String,
  },

  setup(props) {
    const config = {
      nextPageUrl: props.fetchUrl,
      orderBy: {
        recent: "recent",
        delivery: "delivery",
        popular: "popular"
      },
      state: {
        favorite: "favorite",
        offered: "offered",
        active: "active",
        delivered: "delivered",
        deleted: "deleted"
      }
    };

    const state = reactive({
      results: [],
      loading: true,
      search: "",
      orderBy: config.orderBy.recent  + '|desc',
      state: config.state.favorite,
    });

    watch(() => [state.orderBy, state.state], fetchResults);

    onMounted(() => {
        fetchResults();
        window.onscroll = function (e) {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !state.loading) {
              fetchMoreResults();
            }
        };
    });

    async function fetchResults(){
      state.loading = true;
      let orderBy = state.orderBy.split('|');

      await axios
        .get(props.fetchUrl, {
          params: {
            state: state.state,
            orderBy: orderBy[0],
            sort: orderBy[1],
            search: state.search
          }
        })
        .then((response) => {
          config.nextPageUrl = response.data.next_page_url;
          state.results = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    }

    async function fetchMoreResults() {
      if (config.nextPageUrl != null) {
        state.loading = true;
        await axios
          .get(config.nextPageUrl)
          .then((response) => {
            config.nextPageUrl = response.data.next_page_url;
            state.results.push(...response.data.data);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
          state.loading = false;
        });
      } else {
        console.log("No more results");
      }
    }

    function searchChanged(){
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(fetchResults, SEARCH_TIMEOUT);
    }

    return {
      state,
      config,
      searchChanged,
      fetchResults,
      fetchMoreResults,
    };
  },
};
</script>
