<template>
	<div class="column is-6-tablet is-4-middle is-3-desktop" :key="job.id">
      <div v-if="job.highlight" class="ad">
          <div class="columns is-mobile_ is-multiline is-job">

              <!-- <div class="column column--nowide is-3-mobile_ is-12-desktop blk2">
                  <div class="ad__img-bg" :style="{ backgroundImage: 'url(' + job.highlight.url + ')' }"></div>
              </div> -->
              <br />
              <div class="column is-12 blk1">
                  <h2 class="ad__title"><a :href="'/anuncios/ver/' + job.id">{{ job.title.toUpperCase() }}</a></h2>
              </div>

              <div class="column is-12 blk3">
                  <div class="ad__needs">¿QUÉ NECESITO?</div>
                  <div class="ad__description" v-html="job.description.length > 150 ? nl2br(job.description.substring(0,150)) + ' [...]' : nl2br(job.description)"></div>
              </div>

              <div class="column is-9-mobile_ is-12-desktop">
                  <div class="ad__info">
                      <div v-if="job.status_id == 1" class='ad__info-item'>
                          <img :src="icons.date" alt="Fecha" class='ad__info-item-img'>
                          <strong >Plazo deseado:</strong> {{ days(job.delivery_time, job.created_at).replace(/\//g, '.') }}
                      </div>
                      <div v-else-if="job.user_offer" class='ad__info-item'>
                          <img :src="icons.date" alt="Fecha" class='ad__info-item-img'>
                          <strong >Plazo aceptado:</strong> {{ days(job.user_offer.delivery_time, job.user_offer.accepted_at).replace(/\//g, '.') }}
                      </div>
                  </div>
                  <div class="ad__actions ad__actions--noborder">
                    <div style="display: inline-block" title="Compartir">
  <!-- <a data-fancybox data-type="iframe" data-src='{{ route('popups.info', $job->user->id) }}' href="javascript:;" class="ic ic--destaca"></a> -->
    <div
      class="ic ic--share"
      :data-tooltip-content="'#ad-share-' + jobId"
      ref="btnTooltip"
    ></div>
  </div>
                   </div>
              </div><!-- end blk4 -->

              <div class="columns ad__end is-12 blk5">
                <div v-if="loggedId && certificate == 'Sí'" class="column is-12-mobile is-12-desktop business__name--box">
                    <div v-if="job.status_id == 1" class="column is-narrow business__logo-wrapper">
                        <a :href="'/perfil/' + job.user.slug"><img :src="job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                    </div>
                    <div v-else-if="job.user_offer" class="column is-narrow business__logo-wrapper">
                            <a :href="'/perfil/' + job.user_offer.user.slug"><img :src="job.user_offer.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                        </div>
                        <h3 v-if="job.status_id == 1" class="business__name 77777"><a :href="'/perfil/' + job.user.slug">{{ job.user.company.toUpperCase() }}</a></h3>
                        <h3 v-else-if="job.user_offer" class="business__name 6666"><a>{{ job.user_offer.user.company.toUpperCase() }}</a></h3>
                    <div v-if="job.user_offer" class="business__badge"><img :src="'/images/icon-is-verified.svg'" alt="Verified"></div>
                </div>
                <div v-else class="column is-12-mobile is-12-desktop business__name--box">
                    <div v-if="job.status_id == 1" class="column is-narrow business__logo-wrapper">
                        <a :href="'/perfil/' + job.user.slug"><img :src="job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                    </div>
                    <div v-else class="column is-narrow business__logo-wrapper">
                            <a :href="'/perfil/' + job.user_offer.user.slug"><img :src="job.user_offer.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                        </div>
                    <h3 v-if="job.status_id == 1" class="business__name 6666"><a>{{ job.user.company.toUpperCase() }}</a></h3>
                    <h3 v-else class="business__name 6666"><a>{{ job.user_offer.user.company.toUpperCase() }}</a></h3>
                </div>
                <div class="column column--left is-12-mobile is-6-desktop 5555">
                    <div class="">
                        <div class="ad__business business">
                            <div class="columns is-mobile is-gapless">
                                <!-- <div class="column is-narrow business__logo-wrapper">
                                    <a :href="'/perfil/' + job.user.slug"><img :src="job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                                </div> -->
                                <div class="column">
                                    <!-- <h3 class="business__name mmm"><a :href="'/perfil/' + job.user.slug">{{ job.user.company }}</a></h3> -->
                                    <div class="business__rating rating">
                                        <span class="rating__stars" v-if="job.user?.rating > 0">
                                            <img :src="icons.star" alt="Star" class='rating__star' v-for="i in Math.round(job.user?.rating)" :key="i">
                                             <em>{{ job.user?.reviewers }}</em>
                                        </span>
                                        <span class="rating__stars" v-else>
                                            <img :src="icons.nostar" alt="Star" class='rating__star' v-for="i in Math.round(5)" :key="i">
                                             <em>{{ job.user?.reviewers }}</em>
                                        </span>
                                    </div>
                                    <div class="business__location location">
                                        <img :src="icons.location" alt="Location" class='location__icon'> {{ job.user.city.name.length > 18 ? job.user.city.name.substring(0, 18) + '...' : job.user.city.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="column column--rigth is-12-mobile is-6-desktop">
                    <div v-if="certificate == 'Sí'" class="ad__actions_">
                        <div v-if="loggedId">

                            <a v-if="loggedId == job.user_id" class="text-btn ad__cta" :href="'/anuncios/ver/' + job.id">Ver detalles</a>
                            <a v-else-if="job.offer_sent" class="text-btn ad__cta" :href="'/anuncios/ver/' + job.id">Oferta ya realizada</a>
                            <!-- <a v-else data-fancybox data-type="iframe" :data-src="`/offers/offer/${job.id}`" href="javascript:;" class="text-btn ad__cta">Ofertar</a> -->
                            <a v-else class="text-btn ad__cta" :href="'/anuncios/ver/' + job.id">Ver detalles</a>
                        </div>
                        <div v-else>
                            <a href="javascript:;" class="text-btn ad__cta" @click="openLogin">Ofertar</a>
                        </div>
                    </div>
                    <div v-else class="ad__actions_">
                        <div>
                            <a class="text-btn ad__cta" :href="'/anuncios/ver/' + job.id">Ver detalles</a>
                        </div>

                    </div>

                </div>

              </div><!-- end blk5 -->


          </div>
      </div>

      <div v-else class="ad ad--noimg">
          <div class="columns is-mobile_ is-multiline is-job">

                <!-- <div class="column column--nowide is-3-mobile_ is-12-desktop blk2">
                    <div class="ad__img-bg" :style="{ backgroundImage:  'url(' + imageUrl + ')' }"></div>
                </div> --><!-- end blk2 -->
                <br />

                <div class="column is-12 blk1">
                    <h2 class="ad__title"><a :href="'/anuncios/ver/' + job.id">{{ job.title.toUpperCase() }}</a></h2>
                </div><!-- end blk1 -->

                <div class="column is-12 blk3">
                    <div class="ad__needs">¿QUÉ NECESITO?</div>
                    <div class="ad__description" v-html="job.description.length > 150 ? nl2br(job.description.substring(0,150)) + ' [...]' : nl2br(job.description)"></div>
                </div><!-- end blk3 -->


                <div class="column is-9-mobile is-12-desktop blk4_">
                    <div class="ad__info">
                        <div v-if="job.status_id == 1" class='ad__info-item'>
                          <img :src="icons.date" alt="Fecha" class='ad__info-item-img'>
                          <strong >Plazo deseado:</strong> {{ days(job.delivery_time, job.created_at).replace(/\//g, '.') }}
                      </div>
                      <div v-else-if="job.user_offer" class='ad__info-item'>
                          <img :src="icons.date" alt="Fecha" class='ad__info-item-img'>
                          <strong >Plazo aceptado:</strong> {{ days(job.user_offer.delivery_time, job.user_offer.accepted_at).replace(/\//g, '.') }}
                      </div>
                    </div>
                    <div class="ad__actions ad__actions--noborder">
                        <div v-if="loggedId && certificate == 'Sí'">
                            <div v-if="loggedId != job.user_id">
                            <job-actions :userId="String(loggedId)" :jobId="String(job.id)" :ownerId="String(job.user_id)" :markFavorite="job.is_favorite" :collaborators="job.collaborators" :status="job.status_id" :icons="icons"></job-actions>
                            </div>
                            <div v-else>
                            <job-actions :userId="String(loggedId)" :jobId="String(job.id)" :ownerId="String(job.user_id)" :userIs="1" :markFavorite="job.is_favorite" :collaborators="job.collaborators" :status="job.status_id" :icons="icons"></job-actions>
                            </div>
                        </div>
                    </div>
                </div><!-- end blk4 -->

                <div class="columns ad__end is-12 blk5">


                    <div v-if="loggedId && certificate == 'Sí'" class="column is-12-mobile is-12-desktop business__name--box">
                        <div v-if="job.status_id == 1" class="column is-narrow business__logo-wrapper">
                            <a :href="'/perfil/' + job.user.slug"><img :src="job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                        </div>
                        <div v-else-if="job.user_offer" class="column is-narrow business__logo-wrapper">
                            <a :href="'/perfil/' + job.user_offer.user.slug"><img :src="job.user_offer.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                        </div>
                        <h3 v-if="job.status_id == 1" class="business__name 77777"><a :href="'/perfil/' + job.user.slug">{{ job.user.company.toUpperCase() }}</a></h3>
                        <h3 v-else-if="job.user_offer" class="business__name 6666"><a>{{ job.user_offer.user.company.toUpperCase() }}</a></h3>
                        <div v-if="job.user_offer" class="business__badge"><img :src="'/images/icon-is-verified.svg'" alt="Verified"></div>
                    </div>
                    <div v-else class="column is-12-mobile is-12-desktop business__name--box">
                        <div v-if="job.status_id == 1" class="column is-narrow business__logo-wrapper">
                            <a :href="'/perfil/' + job.user.slug"><img :src="job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                        </div>
                        <div v-else class="column is-narrow business__logo-wrapper">
                            <a :href="'/perfil/' + job.user_offer.user.slug"><img :src="job.user_offer.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                        </div>
                        <h3 v-if="job.status_id == 1" class="business__name 6666"><a>{{ job.user.company.toUpperCase() }}</a></h3>
                        <h3 v-else class="business__name 6666"><a>{{ job.user_offer.user.company.toUpperCase() }}</a></h3>
                        <div class="business__badge"><img :src="'/images/icon-is-verified.svg'" alt="Verified"></div>
                    </div>


                    <div class="column column--left is-12-mobile is-6-desktop">
                        <div class="ad__actions_">
                            <div class="ad__business business">
                                <div class="columns is-mobile is-gapless">
                                    <!-- <div class="column is-narrow business__logo-wrapper">
                                        <a :href="'/perfil/' + job.user.slug"><img :src="job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                                    </div> -->
                                    <div class="column">
                                        <!-- <h3 class="business__name mmm"><a :href="'/perfil/' + job.user.slug">{{ job.user.company }}</a></h3> -->
                                        <div class="business__rating rating">
                                            <span class="rating__stars" v-if="job.user?.rating > 0">
                                                <img :src="icons.star" alt="Star" class='rating__star' v-for="i in Math.round(job.user?.rating)" :key="i">
                                                <em>{{ job.user?.reviewers }}</em>
                                            </span>
                                            <span class="rating__stars" v-else>
                                                <img :src="icons.nostar" alt="Star" class='rating__star' v-for="i in Math.round(5)" :key="i">
                                                <em>{{ job.user?.reviewers }}</em>
                                            </span>
                                        </div>
                                        <div v-if="job.status_id == 1" class="business__location location">
                                            <img :src="icons.location" alt="Location" class='location__icon'> {{ job.user.city.name.length > 18 ? job.user.city.name.substring(0, 18) + '...' : job.user.city.name }}
                                        </div>
                                        <div v-else-if="job.user_offer" class="business__location location">
                                            <img :src="icons.location" alt="Location" class='location__icon'> {{ job.user_offer.user.city.name.length > 18 ? job.user_offer.user.city.name.substring(0, 18) + '...' : job.user_offer.user.city.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="column column--rigth is-12-mobile is-6-desktop">

                        <div v-if="certificate == 'Sí'" class="ad__actions_">
                            <div v-if="loggedId">

                                <a v-if="loggedId == job.user_id" class="text-btn ad__cta" :href="'/anuncios/ver/' + job.id">Ver detalles</a>
                                <a v-else-if="job.offer_sent" class="text-btn ad__cta" :href="'/anuncios/ver/' + job.id">Oferta ya realizada</a>
                                <!-- <a v-else data-fancybox data-type="iframe" :data-src="`/offers/offer/${job.id}`" href="javascript:;" class="text-btn ad__cta">Ofertar</a> -->
                                <a v-else class="text-btn ad__cta" :href="'/anuncios/ver/' + job.id">Ver detalles</a>
                            </div>
                            <div v-else>
                                <a href="javascript:;" class="text-btn ad__cta" @click="openLogin">Ofertar</a>
                            </div>
                        </div>
                        <div v-else class="ad__actions_">
                            <div>
                                <a class="text-btn ad__cta" :href="'/anuncios/ver/' + job.id">Ver detalles</a>
                            </div>

                        </div>

                    </div>
                </div><!-- end blk5 -->
          </div>
      </div>

    </div>
</template>

<script>



import JobActions from './JobActions.vue'
import { nl2br } from '../functions'

export default {
	components: { JobActions },
	props: {
		job: Object,
        loggedId: String,
        icons: Object,
        certificate: String,
	},
    setup(){
        const imageName = 'no-photo.png';
        const imageUrl = window.location.origin + '/images/' + imageName;
        function openLogin() {
            $.fancybox.open({
                src: '.login-form',
                type: 'inline',
                touch: false
            })
        }
        function days(day, jobdate) {
            var date = new Date(jobdate);
            var datefinal = date.setDate(date.getDate() + day);
            datefinal = new Date(datefinal);
            datefinal = datefinal.toLocaleDateString();
            return datefinal;
        }

        return {
            nl2br,
            openLogin,
            imageUrl,
            days
        }
    },
}


</script>
