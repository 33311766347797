<template>
    <div class="search-bar__checks">
        <label> <input type="radio" name="model" value="business" v-model="model"/> Empresas </label>
        <label> <input type="radio" name="model" value="jobs" v-model="model" checked /> Anuncios </label>
    </div>
    <input type="text" name="search" class="search-bar__input" v-model="search" />
    <button type="submit" class="button search-bar__btn">
        <span class="search-bar__btn-text">Buscar</span>
    </button>
</template>

<script>
export default {
    props: {
        search: String,
        model: String,
    },
};
</script>
