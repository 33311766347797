<template>
		<div class="column is-6-tablet is-4-middle is-3-desktop">
      <div v-if="offer.job.highlight" class="ad">

          <div class="columns is-mobile is-multiline is-job">
              <br>
                <div class="column column--wide is-3-mobile is-12-desktop blk2" style="display:none;">
                    <div class="ad__img-bg" :style="{ backgroundImage: 'url(' + offer.job.highlight.url + ')' }"></div>
                </div><!-- end blk2 -->

                <div class="column is-12 blk1">
                    <h2 class="ad__title"><a :href="'/anuncios/ver/' + offer.job.id">{{ offer.job.title }}</a></h2>
                    <!-- <h2 class="ad__title"><a :href="route('jobs.show',offer.job.id)">{{ offer.job.title }}</a></h2> -->
                </div> <!-- end blk1 -->

                <div class="column is-12 blk3">
                    <div class="ad__needs">¿QUÉ NECESITO?</div>
                    <div class="ad__description">
                        {{ offer.job.description.length > 300 ? offer.job.description.substring(0,220) + '[...]' : offer.job.description }}
                    </div>
                </div><!-- end blk3 -->

                <!-- <div class="column is-12 blk2">
                    <div class="ad__description"><strong>Atención de {{offer.name}}</strong></div>
                </div> -->

                <div class="column is-9-mobile is-12-desktop blk4_">
                    <div class="ad__info">
                        <div class='ad__info-item'>
                            <img :src="icons.date" alt="Fecha" class='ad__info-item-img'>
                            <strong>Plazo deseado:</strong> {{ days(offer.job.delivery_time, offer.job.created_at).replace(/\//g, '.') }}
                        </div>
                    </div>
                    <div class="ad__actions ad__actions--noborder">
                        <div class="column_ is-12-mobile_ is-12-desktop_ business__name--box_ offer-status">
                            <span v-if="offer.status_id == 3" class="text-expiration">{{ offer.job.delivery_time >= currentDate ? 'Quedan ' + Math.abs(offer.days_remaining) + ' días' : 'Fuera de fecha' }}</span>
                        </div>
                    </div>

                        <!--
                        <div class="ad__business business">
                            <div class="columns is-mobile is-gapless">
                                <div class="column is-narrow business__logo-wrapper">
                                    <a :href="'/perfil/' + offer.job.user.slug"><img :src="offer.job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                                </div>
                            </div>
                        </div>-->

                </div><!-- end blk4 -->

                <div class="columns ad__end is-12 blk5">


                    <div class="column is-12-mobile is-12-desktop business__name--box">
                        <div class="column is-narrow business__logo-wrapper">
                            <a :href="'/perfil/' + offer.job.user.slug"><img :src="offer.job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                        </div>
                       <h3 class="business__name"><a :href="'/perfil/' + offer.job.user.slug">{{ offer.job.user.company }}</a></h3>
                       <div class="business__badge"><img :src="'/images/icon-is-verified.svg'" alt="Verified"></div>
                    </div>




                    <div class="column column--left is-12-mobile is-6-desktop">
                        <div class="">
                            <div class="ad__business business">
                                <div class="columns is-mobile is-gapless">
                                    <div class="column">
                                        <div class="business__rating rating">
                                            <span class="rating__stars" v-if="offer.user?.rating > 0">
                                                <img :src="icons.star" alt="Star" class='rating__star' v-for="i in Math.round(offer.user?.rating)" :key="i">
                                                <em>{{ offer.user?.reviewers }}</em>
                                            </span>
                                        </div>
                                        <div class="business__location location">
                                            <img :src="icons.location" alt="Location" class='location__icon'> {{ offer.job.user.city.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="column column--rigth is-12-mobile is-6-desktop">
                        <div class="">
                            <a :href="'/anuncios/ver/' + offer.job.id" class="text-btn ad__cta">Ver detalles</a>
                        </div>


                    </div>

                </div><!-- end blk5 -->
          </div>
      </div>

      <div v-else class="ad ad--noimg">
            <br />
          <div class="columns is-mobile is-multiline is-job">
                <div class="column is-12 blk1">
                    <h2 class="ad__title"><a :href="'/anuncios/ver/' + offer.job.id">{{ offer.job.title }}</a></h2>
                </div><!-- end blk1 -->

                <div class="column is-12 blk3">
                    <div class="ad__needs">¿QUÉ NECESITO?</div>
                    <div class="ad__description">
                        {{ offer.job.description.length > 300 ? offer.job.description.substring(0,220) + '[...]' : offer.job.description }}
                    </div>
                </div><!-- end blk3 -->

                <div class="column is-9-mobile_ is-12-desktop">
                    <div class="ad__info">
                        <div class='ad__info-item'>
                            <img :src="icons.date" alt="Fecha" class='ad__info-item-img'>
                            <strong>Plazo deseado:</strong> {{ days(offer.job.delivery_time, offer.job.created_at).replace(/\//g, '.') }}
                        </div>
                    </div>
                    <div class="ad__actions ad__actions--noborder">
                        <div class="column_ is-12-mobile_ is-12-desktop_ business__name--box_ offer-status">
                            <span v-if="offer.status_id == 3" class="text-expiration">Quedan {{ Math.abs(offer.days_remaining) }}  días</span>
                        </div>
                    </div>
                        <!--
                        <div class="ad__business business">
                            <div class="columns is-mobile is-gapless">
                                <div class="column is-narrow business__logo-wrapper">
                                    <a :href="'/perfil/' + offer.job.user.slug"><img :src="offer.job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                                </div>
                            </div>
                        </div> -->
                </div><!-- end blk4 -->

                <div class="columns ad__end is-12 blk5">


                    <div class="column is-12-mobile is-12-desktop business__name--box">
                        <div class="column is-narrow business__logo-wrapper">
                            <a :href="'/perfil/' + offer.job.user.slug"><img :src="offer.job.user.logo_url" alt="logo de la empresa" class="business__logo"></a>
                        </div>
                       <h3 class="business__name"><a :href="'/perfil/' + offer.job.user.slug">{{ offer.job.user.company }}</a></h3>
                       <div class="business__badge"><img :src="'/images/icon-is-verified.svg'" alt="Verified"></div>
                    </div>

                    <div class="column column--left is-12-mobile is-6-desktop">
                        <div class="">
                            <div class="ad__business business">
                                <div class="columns is-mobile is-gapless">
                                    <div class="column">
                                        <div class="business__rating rating">
                                            <span class="rating__stars" v-if="offer.job.user?.rating > 0">
                                                <img :src="icons.star" alt="Star" class='rating__star' v-for="i in Math.round(offer.job.user?.rating)" :key="i">
                                                <em>{{ offer.job.user?.reviewers }}</em>
                                            </span>
                                        </div>
                                        <div class="business__location location">
                                            <img :src="icons.location" alt="Location" class='location__icon'> {{ offer.job.user.city.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column column--rigth is-12-mobile is-6-desktop">
                        <div class="">
                            <a :href="'/anuncios/ver/' + offer.job.id" class="text-btn ad__cta">Ver detalles</a>
                        </div>
                    </div>

                </div><!-- end blk5 -->
          </div>
      </div>
    </div>
</template>

<script>
export default {
    currentDate: new Date(),
	props:{
		offer: Object,
		icons: Object,
	},

	setup(){
        function days(day) {
            var date = new Date();
            var datefinal = date.setDate(date.getDate() + day);
            datefinal = new Date(datefinal);
            datefinal = datefinal.toLocaleDateString();
            return datefinal;
        }

        return {
            days
        }
	}
}
</script>
