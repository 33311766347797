<template>
<div>
    <div class="filters-box">
        <div class="filters-tabs">
            <a href="javascript:void(0);" onclick="window.location.reload();" class="filters-tabs__link"><span>Empresa</span></a><a href="/" class="filters-tabs__link filters-tabs__link--select"><span>Anuncios</span></a>
        </div>
        <div class="filters">
            <div class="filters__main">
                <label for="sorting" class="filters__sort-label">Mostrar por:</label>
                <select id="sorting" class="filters__select" v-model="state.orderBy">
                    <option value="popular">Valoraciones</option>
                    <option value="nocollabs">No colaboradores</option>
                    <option value="verified">Empresas Verificadas</option>
                    <option value="company" selected>Alfabéticamente</option>
                    <option value="recent">Fecha de creación más reciente</option>
                </select>
            </div>
            <div class="filters__right">
                <button class="button filters__tgl js-filters-tgl">Filtros</button>
                <div class="filters__content">
                    <span class="filters__geoloc">
                        <span class="filters__geoloc-label">Geolocalización: </span>
                        <button class="filters__btn" v-if="!state.location" @click="getLocation">Activar</button>
                        <button class="filters__btn" v-else @click="state.location = null">Desactivar</button>
                    </span>
                </div>
            </div>
        </div>
    </div>

		<div class="columns is-multiline">
            <div class="column is-6-tablet is-4-middle is-3-desktop" v-for="business in state.businesses" :key="business.id">
                <div  class="business-card ad">

                    <a :href="'/perfil/' + business?.slug" class="business-card__bg" :style="[business.banner_url ? `background-image: url(${business?.banner_url});` : 'backgroundImage:  url(' + imageUrl + ')']">
                        <div class="business-card__logo"><img :src="business.logo_url ? business.logo_url : imageUrl"></div>
                    </a>
                    <div class="business-card__content">
                        <h2 class="business-card__name"><a :href="'/perfil/' + business?.slug">{{ business?.company }}</a>
					        <div v-if="business.certificate == 'Sí'" class="profile__badge"><img :src="icons.verify" alt="Verified"></div> </h2>
                        <div class="business-card__intro">{{ business && business.description ? business.description.substring(0, 60) + (business.description.length > 50 ? '...' : '') : '' }}</div>

                    </div>

                        <div class="business-card__bottom">
                            <div class="business-card__stats">
                                <!-- <div>Colaboradores: {{ business?.collaborators_count }}</div> -->
                                <div class="rating">
                                    <span class="rating__stars" v-if="business?.reviewers > 0">
                                        <img :src="icons.star" alt="Star" class='rating__star' v-for="i in Math.round(business?.rating)" :key="i"> <em>{{ business?.reviewers }}</em>
                                    </span>
                                    <span class="rating__stars" v-else>
                                        <img :src="icons.nostar" alt="Star" class='rating__star' v-for="i in Math.round(5)" :key="i"> <em>{{ business?.reviewers }}</em>
                                    </span>
                                </div>
                                <div class="location">
                                    <img :src="icons.location" alt="Location" class='location__icon'> {{ business?.city?.name }}
                                </div>
                            </div>

                            <div class="business-card__btns mybtns">
                                <div class="business-card__btnsleft">
                                    <div class="ic ic--share js-tooltip-white" :data-tooltip-content="'#ad-share-'+business?.id"></div>
                                    <div class="ic ic--businesses" data-fancybox data-type="iframe" :data-src="`/popup/colaboradores?id=${business.id}`" href="javascript:;">{{ /* fetchCollaborators(business.id) */ }}</div>
                                </div>
                                <div class="business-card__btnsright">
                                    <a :href="'/perfil/' + business?.slug" class="text-btn ad__cta">Saber más</a>
                                    <!-- <a data-fancybox data-type="iframe" :data-src="`/popup/anuncio?id=${jobId}`" class="ic ic--businesses-send" title="Escribir al anunciante"></a> -->
                                </div>
                            </div>
                        </div>


                </div>

                <div class="tooltip_templates">
                    <div class='tooltip ad-tooltip' :id="'ad-share-' + business?.id">
                        <nav>
                            <a href="#" class='ad-tooltip__link'><img class='ad-tooltip__icon' :src="icons.email" alt="Icon">Enviar Email</a>
                            <a href="#" class='ad-tooltip__link'><img class='ad-tooltip__icon' :src="icons.whatsapp" alt="Icon">WhatsApp</a>
                            <a href="#" class='ad-tooltip__link'><img class='ad-tooltip__icon' :src="icons.twitter" alt="Icon">Twitter</a>
                            <a href="#" class='ad-tooltip__link'><img class='ad-tooltip__icon' :src="icons.linkedin" alt="Icon">Linkedin</a>
                            <a href="#" class='ad-tooltip__link'><img class='ad-tooltip__icon' :src="icons.facebook" alt="Icon">Facebook</a>
                        </nav>
                    </div>
                </div>
            </div>
		</div>
        <div class="spinner" v-show="state.loading"></div>
</div>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { onMounted, watch } from '@vue/runtime-core';
import axios from 'axios';
export default {
    props: {
        search: String,
        fetchUrl: String,
        icons: Object,
        ownerId: Number,
    },
    data() {
        return {
        businessId: ''
        };
    },

    setup(props){
        const config = {
            nextPageUrl: props.fetchUrl,
        };

        const whatsappMessage = "Hola! Me interesó mucho este anuncio y creo que a ti también puede llegar a interesarte";
        const shareUrl = `${new URL(document.URL).origin}/anuncios/ver/${props.jobId}`;
        const imageName = 'no-photo.png';
        const imageUrl = window.location.origin + '/images/' + imageName;
        const shareLinks = {
            whatsapp: `https://wa.me/?text=${whatsappMessage}%20${shareUrl}`,
            twitter: `https://twitter.com/share?url=${shareUrl}`,
            facebook: `https://es-es.facebook.com/sharer.php?u=${shareUrl}`,
            linkedin: `https://es.linkedin.com/sharing/share-offsite/?url=${shareUrl}`
        }
        const state = reactive({
            loading: false,
            businesses: [],
            location: null,
            orderBy: "slug",
            ownerId: null
        });

        /* async function checkIfBusinessInList(businessId) {
            try {
                console.log('id='+businessId);
                console.log('/colaboradores_get1/{'+businessId+'}');
                const response = await axios.get('/colaboradores_get1/{'+businessId+'}'); // Reemplaza esto con la URL correcta que apunte a tu controlador de Laravel
                console.log(response);
                const isInList = response.data.inList;

                if (isInList) {
                // Si el business.id está en la lista
                console.log(`El business.id ${businessId} está en tu lista.`);
                // Realiza las acciones que desees cuando esté en la lista
                } else {
                // Si el business.id NO está en la lista
                console.log(`El business.id ${businessId} NO está en tu lista.`);
                // Realiza las acciones que desees cuando NO esté en la lista
                }
            } catch (error) {
                console.error('Error al realizar la llamada AJAX:', error);
                // Maneja el error si es necesario
            }
        } */

        watch(() => [state.location, state.orderBy], fetchResults);
        onMounted(() => {

            state.ownerId = props.ownerId;
            fetchId();
            fetchResults();
            window.onscroll = function (e) {
                if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !state.loading) {
                    fetchMoreResults();
                }
            };
        });

        async function fetchId(){
            state.loading = true;
            axios.get('/get-user-id')
            .then(response => {
                console-log(1);
                this.ownerId = response.data.id;
            })
            .catch(error => {
                console.log(error);
            });
        }

        async function fetchResults(){
            state.loading = true;
            await axios
                .get(props.fetchUrl, {
                    params: {
                        search: props.search,
                        orderBy: state.orderBy,
                        location: state.location
                    }
                })
                .then((response) => {
                    config.nextPageUrl = response.data.next_page_url;
                    state.businesses = response.data.data;
                })
                .then(initializeTooltips)
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    state.loading = false;
                });
        }

        async function fetchMoreResults() {
            if (config.nextPageUrl != null) {
                state.loading = true;
                await axios
                .get(config.nextPageUrl)
                .then((response) => {
                    config.nextPageUrl = response.data.next_page_url;
                    state.businesses.push(...response.data.data);
                })
                .then(initializeTooltips)
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                state.loading = false;
                });
            } else {
                console.log("No more results");
            }
        }

        /* async function fetchCollaborators(id){
            console.log(id);
            checkIfBusinessInList(id);
        } */



        function initializeTooltips(){
            // Initialize tooltipster ad tooltip
            $('.js-tooltip-white').tooltipster({
                trigger: 'click',
                contentAsHTML: true,
                interactive: true,
                animationDuration: 0,
                side: 'bottom',
                theme: 'tooltipster-white',
                functionBefore: function (instance, helper) {
                var btn = helper.origin
                btn.classList.add('on')
                },
                functionAfter: function (instance, helper) {
                var btn = helper.origin
                btn.classList.remove('on')
                }
            });
        }

        function getLocation() {
            if (!navigator.geolocation) {
                alert("La funcionalidad de geolocalización no es compatible con este navegador.");
            } else {

                navigator.permissions.query({name: 'geolocation'})
                .then((permission) => {
                if(permission.state == "denied"){
                    alert("Debes activar la geolocalización en el navegador para poder usar esta función");
                }else{
                    navigator.geolocation.getCurrentPosition(success, error);
                }
                })
                .catch((error) => {
                alert("No se ha podido obtener el estado del permiso de geolocalización");
                })
            }

            function success(position) {
                state.location = {
                coords: {
                accuracy: position.coords.accuracy,
                altitude: position.coords.altitude,
                altitudeAccuracy: position.coords.altitudeAccuracy,
                heading: position.coords.heading,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                speed: position.coords.speed,
                },
                timestamp: position.timestamp,
                };
            }

            function error() {
                state.location = null;
            }
        }



        return {
            state,
            config,
            imageUrl,
            getLocation,
            /* fetchCollaborators, */
        };
    }
};
</script>
