<template>
    <div class="collab-list" :class="{ active: openAccordion }">
        <div class="collab-list__header">
            <!-- v-show enlloc de v-if/v-else necessari per a funcionament de la label->input -->
            <label v-show="!state.editTitle" :for="`${state.collaboration.name}Label`" @click="state.editTitle = true">
                <h3 class='collab-list__name' >{{ state.collaboration.name }}</h3>
            </label>
            <input v-show="state.editTitle" type="text" ref="collaborationInput" :id="`${state.collaboration.name}Label`" v-model="state.collaboration.name" placeholder="Añade un nombre" @blur="saveName" v-on:keyup.enter="unfocus($event)">
            <div class='collab-list__actions'>
                <label :for="`${state.collaboration.name}Label`">
                    <img class='collab-list__action' :src="icons.edit" alt="" @click="state.editTitle = !state.editTitle">
                </label>
                <img class='collab-list__action' :src="icons.trash" alt="" data-fancybox :data-src="'#add-deletelist' + state.collaboration.id" :data-id=index>
                <img class="collab-list__action collab-list__arrow" :src="icons.toggle" alt="" @click="openAccordion = !openAccordion">
            </div>
        </div>

        <!-- Modal eliminar lista -->
        <div style="display: none; width: 580px" :id="'add-deletelist' + state.collaboration.id" ref="storeFancybox"> <!-- REVISAR INDEX  -->
            <h2>Estas seguro que deseas eliminar tu lista {{ state.collaboration.name }}?</h2>
            <div style="margin-bottom: 1rem;" class="text-center"> Escribe "ELIMINAR" si estas conforme
                <input type="text" :id="'textdeletelist' + state.collaboration.id" ref="addCollaboration" class="input" v-on:keyup.enter="storeCollaboration">
            </div>
            <button type="button" class="button" ref="addCollaborationBtn" @click="detachList(state.collaboration.id)">Eliminar</button>
        </div>


        <div class="collab-list__content">
            <!-- List item -->
            <table v-for="(collaborator, index) in state.collaboration.collaborators" :key="index" class="collab-list__item">
                <tr v-if="collaborator.published == 'Sí'">
                    <td width='55' class="collab-list__item-logo">
                        <a v-if="collaborator.logo_id == null" :href="'/perfil/' + collaborator.slug" target="_blank"><img :src=" imageUrl " ></a>
                        <a v-else :href="'/perfil/' + collaborator.slug" target="_blank"><img :src="collaborator.logo_url"></a>
                    </td>
                    <td class="collab-list__item-name">{{ collaborator.company }} ({{ collaborator.city.name }})</td>
                    <td class="collab-list__item-actions">
                        <!-- <img class='collab-list__action js-tooltip-white' :data-tooltip-content="'#send-to-' + collaborator.id" :src="icons.send" alt=""> -->
                        <img class='collab-list__action' :src="icons.edit" alt="" data-fancybox :data-src="'#move-collaboratindex' +'_'+ state.collaboration.id +'_'+ index" :data-id="index" @click="openModal(order)" >
                        <img class='collab-list__action' :src="icons.trash" alt="" data-fancybox :data-src="'#add-collaboratindex' +'_'+ state.collaboration.id +'_'+ index" :data-id="index" @click="openModal(order)" >
                    </td>
                </tr>

                <div class="tooltip_templates" v-if="collaborator.contacts.length">
                    <div class='tooltip ad-tooltip' :id="'send-to-' + collaborator.id">
                        <nav>
                            <div v-for="contact in collaborator.contacts" :key="contact.id" class='ad-tooltip__link'>{{ contact.name }}</div>
                        </nav>
                    </div>
                </div>
                <!-- Modal eliminar colaborador -->   <!-- IMPORTANT NOMBRAR EL POPUP DE LA MANERA CORRECTA PER CONFUSIONS -->
                <div style="display:none; width: 580px" :id="'add-collaboratindex' + '_' + state.collaboration.id + '_' +index" ref="storeFancybox"> <!-- REVISAR INDEX  -->
                    <h2>Estas seguro que deseas eliminar a {{ state.collaboration.collaborators[index].company }} como colaborador de tu lista {{ state.collaboration.name}}?</h2>
                    <!-- <div style="margin-bottom: 1rem;" class="text-center"> Escribe "ELIMINAR" si estas conforme
                        <input type="text" :id="'textdelete' + index" ref="addCollaboration" class="input" v-on:keyup.enter="storeCollaboration">
                    </div> -->
                    <button type="button" class="button" ref="addCollaborationBtn" @click="detachCollaborator(index, state.collaboration.id)">Eliminar</button>
                </div>

                <div style="display:none; width: 580px" :id="'move-collaboratindex' + '_' + state.collaboration.id + '_' +index" ref="storeFancybox"> <!-- REVISAR INDEX  -->
                    <h2>A que lista desea mover a {{ state.collaboration.collaborators[index].company }} como colaborador de tu lista {{ state.collaboration.name}}?</h2>
                    <!-- <div style="margin-bottom: 1rem;" class="text-center"> Escribe "ELIMINAR" si estas conforme
                        <input type="text" :id="'textdelete' + index" ref="addCollaboration" class="input" v-on:keyup.enter="storeCollaboration">
                    </div> -->
                    <!-- <div v-for="colaborator in collaborations">{{ colaborator.name}}</div> -->
                    <form action="/list/change" method="post">
                        <input type="hidden" name="lastColab" value="{{ state.collaboration.collaborators[index].company }}">
                        <input type="hidden" name="lastList" value="{{ state.collaboration.id}}">
                        <select class="filters__select" id="selected_colab" v-model="selectedCollaborator" ref="collaboratorSelect">
                            <option disabled value="" selected>Seleccione una lista</option>
                            <option v-for="colaborator in collaborations" v-bind:value="colaborator.id" :key="colaborator.id">
                                {{ colaborator.name }}
                            </option>
                        </select>
                        <br><br>
                        <button type="button" class="button" ref="addCollaborationBtn" @click="changeCollaborator(index, state.collaboration.id, selectedCollaborator)">Cambiar</button>
                    </form>
                </div>

                <!-- <div style="display: none; width: 580px" :id="'add-listindex' + index" ref="storeFancybox">
                    <h2>Estas seguro que deseas eliminar la lista {{ state.collaboration.name }}?</h2>
                    <div style="margin-bottom: 1rem;" class="text-center"> Escribe "ELIMINAR" si estas conforme
                        <input type="text" :id="'textdeletelist' + index" ref="addList" class="input" v-on:keyup.enter="storeCollaboration">
                    </div>
                    <button type="button" class="button" ref="addListBtn" @click="detachList(index)">Eliminar</button>
                </div> -->
            </table>

        </div>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted, watch } from '@vue/runtime-core';
export default {
    props:{
        icons: Object,
        order: Number,
        collaboration: Object,
        collaborations: Object,
        openAccordion: Boolean,
    },

    emits: ["deleteCollaboration"],
    setup(props, { emit }) {
        const collaborationInput = ref(null);
        const collaboratorSelect = ref(null);
        const imageName = 'no-photo.png';
        const imageUrl = window.location.origin + '/images/' + imageName;

        const config = {
            initialName: props.collaboration?.name,
        };

        const state = reactive({
            selectedCollaborator: '',
          editTitle: false,
          collaboration: {
            name: 'Añade un nombre',
            collaborators: [],
            id: -1,
          },
          listIndex: null,
        });

        const listIndex = ref(props.order);

        onMounted(() => {
            state.collaboration = props.collaboration;
        });

        watch(() => props.collaboration, (input, prevInput) => {
            state.collaboration = props.collaboration;
	    })


        /**
         * Funció que executa la petició per actualitzar el nom d'una colaboració
         */
        function saveName(){
            state.editTitle = false;
            if(config.initialName != state.collaboration?.name){
                axios.post('/colaboraciones/store', {
                    collaborationId: state.collaboration.id,
                    name: state.collaboration.name,
                })
                .then(function (response) {
                    config.initialName = state.collaboration.name;
                })
                .catch(function (error) {
                    alert('Ha ocurrido un error');
                });
            }
        }

        function deleteCollaboration(){
            emit('deleteCollaboration', props.collaboration.id);
        }


        /**
         * @param index Number
         * Funció que executa la petició per eliminar una colaboració
         */
        function detachCollaborator(index, list){

            var collaborator = state.collaboration.collaborators[index];

            axios.post('/colaboraciones/detach', {
                    collaborationId: state.collaboration.id,
                    collaboratorId: collaborator.id,

                })
                .then(function (response) {
                    state.collaboration.collaborators.splice(index, 1);
                })
                .catch(function (error) {
                    alert('Ha ocurrido un error');
                });
                console.log(parent.$.fancybox);
                $.fancybox.close();

        }

        function changeCollaborator(index, list, selectedCollaborator){

            var collaborator = state.collaboration.collaborators[index];


            axios.post('/list/change', {
                    collaborationId: state.collaboration.id,
                    collaboratorId: collaborator.id,
                    newCollaborationId: selectedCollaborator,
                })
                .then(function (response) {
                    state.collaboration.collaborators.splice(index, 1);
                })
                .catch(function (error) {
                    alert('Ha ocurrido un error.');
                });
                /* console.log(parent.$.fancybox); */
                $.fancybox.close();
                location.reload();

        }

        function openModal(index) {
            listIndex.value = props.order;
        }

        function detachList(index){

            /* var collaborator = state.collaboration[index]; */
            var text = document.getElementById('textdeletelist' + index).value;
            if (text == 'ELIMINAR') {
                axios.post('/list/detach', {
                        collaborationId: state.collaboration.id,
                    })
                    .then(function (response) {
                        state.collaboration.id = state.collaboration.id;
                    })
                    .catch(function (error) {
                        alert('Ha ocurrido un error');
                    });
                    console.log(parent.$.fancybox);
                    $.fancybox.close();
                    location.reload();
            }
        }

        function unfocus(e){
            e.target.blur();
        };

      return {
        state,
        saveName,
        focus,
        unfocus,
        config,
        collaborationInput,
        detachList,
        openModal,
        deleteCollaboration,
        detachCollaborator,
        changeCollaborator,
        imageUrl
      }
    }
}
</script>
